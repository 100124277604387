<template>
  <div id="body">
    <h6 style="color: blue !important; position: absolute; top: 10px; right: 18px;">
      <router-link to="/home">Home</router-link> &nbsp;&nbsp;&#183;&nbsp;&nbsp; <router-link to="/faq">FAQ</router-link>
    </h6>
  <div class="container form">
		<div class="card0 d-flex flex-lg-row flex-column-reverse">
				<div class="card card1">
					<div class="row justify-content-center my-auto">
						<div class="col-md-12 col-12 my-4">
							
							<h3 class="text-center heading">Log In</h3>
							<h6 class="msg-info text-center">Sign in to your account</h6>
              <form @submit.prevent="login" method="POST">
                <div class="form-group">
                  <span class="fa left fa-user"></span>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    class="form-control input-auth"
                    v-model="email"
                  >
                </div>
							  <div class="form-group">
                  <span class="fa left fa-lock"></span>
                  <input
                    placeholder="Password"
                    class="form-control input-auth"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    name="password"
                  >
                  <span
                    :class="[!showPassword ? 'fa-eye' : 'fa-eye-slash', 'fa form-control-feedback']"
                    @click="showPassword = !showPassword">
                  </span>
                </div>
							  <div class="row justify-content-center my-2">
                  <a href="#" @click="warningModal = true">
                    <small class="text-muted">Forgot Password?</small>
                  </a>
                </div>
							  <div class="row justify-content-center my-3 px-3">
                  <button class="btn btn-primary text-uppercase" type="submit">sign in <CSpinner grow color="100" size="sm" v-if="loading"/></button>
                </div>
                <br>
                <CAlert
                  color="danger"
                  closeButton
                  v-if="showMessage"
                >
                  {{message}}
                </CAlert>
              </form>
						
							<!-- <img src="@/assets/icons/logo-footer.png" class="img-fluid" /> -->
							
						</div>
					</div>
					
				</div>
				<div class="card card2">
					<div class="row justify-content-center mx-auto">
						<div class="col-md-12 col-12 my-4">
							<div class="mx-auto">
								<router-link to="/home">
                  <img src="@/assets/icons/logo-round.png" class="img-fluid logo-right" />
                </router-link>
							</div>
							<h3 class="text-white text-center">Visitor Management System</h3> 
              <!-- <small class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..</small>	 -->
							
						</div>
						<button class="btn btn-primary text-uppercase" @click="goRegister()">Register</button>
					</div>
					
				</div>
			</div>

      <CModal
      centered
      :closeOnBackdrop="false"
      :show.sync="warningModal"
    >
      <div class="row justify-content-center my-auto mx-auto">
						<div class="col-md-12 col-12 my-2 mx-4">
							
							<h3 class="text-center heading px-4 my-5 ">Forgot Your Password</h3>
							<div class="form-group">
                <span class="fa left fa-user"></span>
                <input
                  placeholder="Please fill with registered email"
                  autocomplete="username email"
                  v-model="emailReset"
                  name="email"
                  type="email"
                  class="form-control input-auth">
              </div>
							
							
							<div class="row justify-content-center my-3 px-3">
                <button class="btn btn-primary text-uppercase" @click="resetPwd">reset your password <CSpinner grow color="100" size="sm" v-if="loading2"/></button>
              </div>
              <CAlert
                  color="danger"
                  closeButton
                  v-if="showMessage2"
                >
                  {{message2}}
              </CAlert>
							<div class="row justify-content-center my-2">
                <small>
                  <a href="#" @click="warningModal = false">Back to Sign In</a>
                </small>
              </div>
							<img src="@/assets/icons/logo-footer.png" class="img-fluid my-auto mx-auto px-5 py-4" />
							
						</div>
					</div>
      <template #header><span></span></template>
      <template #footer><span></span></template>
    </CModal>
	</div>
    
  </div>
</template>

<script>
import http from '@/utils/http-common';

export default {
  name: 'Login',
  data() {
    return {
      warningModal: false,
      loading: false,
      emailReset: '',
      email: '',
      password: '',
      showMessage: false,
      message: '',
      showPassword: false,
      loading2: false,
      showMessage2: false,
      message2: '',
      showPassword2: false,
    }
  },
  methods: {
    goRegister(){
      this.$router.push({ path: 'register' });
    },
    login() {
      let self = this;
      self.loading = true;
      self.$store.dispatch('login', {
        email: self.email,
        password: self.password
      })
      .then(function (response) {
        // console.log(response.data.user)
        self.email = '';
        self.password = '';
        self.loading = false;
      })
      .catch(function (error) {
        self.message = 'Incorrect email or password';
        self.showMessage = true;
        self.loading = false;
        console.log(error);
      });
    },
    resetPwd() {
      console.log(this.emailReset)
      let self = this;
      self.loading2 = true;
			return http.post('/reset-password', {
				email: self.emailReset,
			})
			.then(function () {
				self.loading = false;
        self.warningModal = false;
			}).catch(function (err) {
				self.message2 = err.message;
        self.showMessage2 = true;
        self.loading2 = false;
        console.log(err)
			});
    }
  },
}
</script>
<style lang="scss">
  // Import custom styles for this application
  @import '@/assets/css/style';
</style>
